import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import type {AppProps} from 'next/app';
import {UserProvider} from '../context/UserContext';
import {
  config,
  initialize,
  trackPageView,
  isAnalyticsConsented,
} from '../utils/googleAnalytics';
import '../styles/globals.css';
import '../styles/content-body.css';
import MetaPixel from '../components/MetaPixel';
import {appWithTranslation} from 'next-i18next';

const CONSENT_KEY = process.env.NEXT_PUBLIC_COOKIES_CONSENT_KEY;

function App({Component, pageProps}: AppProps) {
  const router = useRouter();
  const [enableTracking, setEnableTracking] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    if (enableTracking) {
      const handleRouteChange = (url: string) => {
        trackPageView(document.title, url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [enableTracking, router.events]);

  useEffect(() => {
    const analysticsConsented = isAnalyticsConsented();

    if (analysticsConsented) {
      return setEnableTracking(true);
    }

    //@ts-expect-error
    window.OptanonWrapper = () => {
      isAnalyticsConsented() && setEnableTracking(true);
    };
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>

      {enableTracking && (
        <>
          <Script strategy="lazyOnload" src={config} />
          <Script strategy="lazyOnload">{initialize}</Script>
        </>
      )}

      <Script
        strategy="lazyOnload"
        src={`https://cdn.cookielaw.org/consent/${CONSENT_KEY}/otSDKStub.js`}
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={CONSENT_KEY}
      ></Script>

      <UserProvider>
        {enableTracking && <MetaPixel />}
        <Component {...pageProps} />
      </UserProvider>
    </>
  );
}

export default appWithTranslation(App);
