import React, {ReactNode} from 'react';

type Children = {children?: ReactNode};

export default function Wrapper({children}: Children) {
  return (
    <div className="relative min-h-screen flex flex-col bg-superLightBrown print:bg-transparent">
      <div className="flex-1 w-full flex flex-col items-center z-10 overflow-auto print:overflow-visible">
        {children}
      </div>
    </div>
  );
}
