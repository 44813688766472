export const config = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`;

export const initialize = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);};
  gtag('js', new Date());
  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {page_path: window.location.pathname,});
`;

export const trackPageView = (title: string, location: string) => {
  //@ts-expect-error
  gtag('event', 'page_view', {
    page_title: title,
    page_location: location,
  });
};

export const isAnalyticsConsented = () => {
  const cookies = document.cookie;

  const consentCookie = cookies
    .split(';')
    .find((item) => item.includes('OptanonConsent'));

  const awaiting = !consentCookie?.includes('AwaitingReconsent=false');

  if (awaiting) {
    return false;
  }

  const analysticsConsented = consentCookie
    ?.split('&')
    .find((item) => item.includes('groups='))
    ?.includes('C0001%3A1%2CC0003%3A1');

  return analysticsConsented;
};
