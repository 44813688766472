import storage from 'local-storage-fallback';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

export async function retrieveAccessToken() {
  const token = storage.getItem(ACCESS_TOKEN_KEY);

  if (token) {
    return token;
  }

  return null;
}

export async function storeAccessToken(token: string | null) {
  if (token) {
    storage.setItem(ACCESS_TOKEN_KEY, token);
  } else {
    storage.removeItem(ACCESS_TOKEN_KEY);
  }
}
