import React, {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {isAxiosError} from 'axios';

import Wrapper from '../components/Wrapper';
import {retrieveAccessToken, storeAccessToken} from '../utils/storage';
import {fetchUser, http} from '../utils/api';
import {useRouter} from 'next/router';

export type UserContextType = {
  user: User | null;
  setToken: (token: string) => Promise<void>;
  logout: () => void;
  refreshUser: () => void;
};

type StoreContextProps = PropsWithChildren<UserContextType>;

export const UserContext = createContext<UserContextType>({
  user: null,
  refreshUser: () => {},
  setToken: async () => {},
  logout: () => {},
});

export const UserProvider = ({children}: Partial<StoreContextProps>) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  const refreshUser = useCallback(async () => {
    try {
      const user = await fetchUser();
      setUser(user);
    } catch (error) {
      setUser(null);
      storeAccessToken(null);
      throw error;
    }
  }, []);

  const handleLogout = useCallback(() => {
    storeAccessToken(null);
    setToken('');
    setUser(null);
    router.reload();
  }, [setUser, router]);

  const handleNewToken = useCallback(
    async (token: string) => {
      try {
        await storeAccessToken(token);
        await refreshUser();
        setToken(token);
      } catch (error) {
        storeAccessToken(null);
        throw error;
      }
    },
    [refreshUser]
  );

  useEffect(() => {
    (async () => {
      try {
        const token = await retrieveAccessToken();
        if (token) {
          await refreshUser();
          setToken(token);
        }

        setLoading(false);
      } catch (error) {
        //TODO: handle error
        // window.alert(error);
        setLoading(false);
      }
    })();
  }, [refreshUser]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const responseInterceptor = http.interceptors.response.use(
      (r) => r,
      (err) => {
        if (isAxiosError(err) && err.response?.status === 401) {
          handleLogout();
        }
        throw err;
      }
    );

    return () => {
      http.interceptors.response.eject(responseInterceptor);
    };
  }, [token, handleLogout]);

  if (loading) {
    return (
      <Wrapper>
        <div className="page-container flex flex-col justify-center items-center p-6">
          <span className="w-10 h-10 border-2 border-brown border-b-lightBrown rounded-full animate-spin" />
        </div>
      </Wrapper>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setToken: handleNewToken,
        logout: handleLogout,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

type RequiredUserContextType = UserContextType & {
  user: User;
};

export const useRequiredUser = (): RequiredUserContextType => {
  const {user, ...context} = useContext(UserContext);

  if (!user) {
    throw new Error('Utente non abilitato.');
  }

  return {
    user,
    ...context,
  };
};
